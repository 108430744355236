import { useEffect, useRef, useState } from "react";
import { adminMembersPaginated } from "../services";
import { CancelToken } from "apisauce";

export const usePagination = ({ hitsPerPage, status, filterValues, ...props }) => {
  const [hits, setHits] = useState<Object[]>([]);
  const [text, setText] = useState<string>("");
  const [page, setPage] = useState("");
  const [nextPage, setNextPage] = useState("");
  const [count, setCount] = useState(0);
  const isEditing = useRef(false);
  const [reloadCount, setReloadCount] = useState(0);
  const [loading, setLoading] = useState(false);

  interface Filter {
    status: string;
    coach: string;
    condition?: object;
  }

  useEffect(() => {
    const source = CancelToken.source();
    const fetchData = async () => {
      try {
        let filter: Filter = { status, coach: props.filterCoach || undefined };

        if (filterValues) {
          const condition = { ...filterValues };
          filter = {
            ...filter,
            condition,
          };
          isEditing.current = true;
        }
        if (text) {
          const condition = {
            field: "FirstName",
            value: text,
            operator: "begins_with",
          };
          filter = {
            ...filter,
            condition,
          };
        }

        setLoading(true);

        const result = await adminMembersPaginated.post({
          max_items: String(hitsPerPage),
          filter,
          page,
        });

        setLoading(false);

        setNextPage(result.data.NextToken);
        if (isEditing.current) {
          setCount(result.data.Count);
        }
        const items = result.data.Items ?? [];
        const data =
          items.map(el => ({
            ...el,
            name: `${el.firstName} ${el.lastName}`,
          })) || [];
        isEditing.current ? setHits(data) : setHits(el => [...(el || []), ...(data || [])]);
      } catch (e) {
        console.info(e);
      }
    };

    fetchData();

    return () => {
      source.cancel();
    };
  }, [text, page, reloadCount, filterValues, props.filterCoach]);

  useEffect(() => {
    isEditing.current = true;
  }, [props.filterCoach]);

  const search = (query: string) => {
    isEditing.current = true;
    setPage("");
    setText(query);
  };

  const next = () => {
    isEditing.current = false;
    setPage(nextPage);
    return;
  };

  const reset = () => {
    isEditing.current = true;
    setPage("");
    setReloadCount(reloadCount + 1);
  };

  return {
    search,
    filter: text,
    count,
    next,
    hasMore: Boolean(nextPage),
    reset,
    hits,
    loading,
  };
};
