import { Auth } from "aws-amplify";
import { userSummary } from "../services";
import { mskApi } from "../services/config";
import { Header } from "../constants";

export async function Authenticate(email, code) {
  try {
    const user = await Auth.signIn(email);
    await Auth.sendCustomChallengeAnswer(user, code);
    await Auth.currentUserInfo();
    return getAuthenticateData();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw new Error(e);
  }
}

export async function updateSession() {
  try {
    const [session] = await Promise.all([Auth.currentSession()]);
    const jwtToken = (session || {}).getAccessToken().getJwtToken();
    const header: Header = {
      authorization: jwtToken,
    };
    mskApi.setHeaders(header);
    localStorage.setItem("rkAccessToken", jwtToken);
    return [session];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw new Error(e);
  }
}

export async function getAuthenticateData() {
  try {
    const [user, session] = await Promise.all([Auth.currentAuthenticatedUser(), Auth.currentSession()]);
    const jwtToken = (session || {}).getAccessToken().getJwtToken();
    const header: Header = {
      authorization: jwtToken,
    };
    mskApi.setHeaders(header);
    localStorage.setItem("rkAccessToken", jwtToken);
    const summary = await userSummary.get();
    return [user, session, summary];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw new Error(e);
  }
}

export async function LogOut() {
  await Auth.signOut();
  Object.keys(localStorage).forEach(key => {
    if (key.startsWith("rk")) {
      localStorage.removeItem(key);
    }
  });
  return localStorage.removeItem("rkAccessToken");
}
