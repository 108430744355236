export const API_BASE_URL = `https://api-${process.env.REACT_APP_DOMAIN_NAME}`;
export const S3_BUCKET_URL = `https://s3.amazonaws.com/cdn.${process.env.REACT_APP_DOMAIN_NAME}`;

export const DISPLAYED_CONTEXTS = {
  hj: "hj",
};

export const STORAGE_DISPLAY_CONTEXT_KEY = "rkDisplayContext";

export type Header = { [key: string]: string };

export const PHONE_NUMBER_REGEX = /((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/;
