// USER
export const UPDATE_USER = "UPDATE_USER";
export const GET_CURRENT_USER = "GET_CURRENT_USER";
export const SET_MAIN_CITY = "SET_MAIN_CITY";
export const UPDATE_PAIN_AREA = "UPDATE_PAIN_AREA";
export const UPDATE_APPLICATION_ACCEPTED = "UPDATE_APPLICATION_ACCEPTED";
export const SELECTED_LANGUAGE = "SELECTED_LANGUAGE";
export const UPDATE_SUMMARY_FAVORITE = "UPDATE_SUMMARY_FAVORITE";

// AUTH
export const USER_LOADED = "USER_LOADED";
export const USER_LOADING = "USER_LOADING";
export const AUTHENTICATING = "AUTHENTICATING";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const UPDATE_AUTH = "UPDATE_AUTH";
export const RESET_SESSION = "RESET_SESSION";
export const AUTHENTICATION_ATTEMPTS = "AUTHENTICATION_ATTEMPTS";
export const USER_TOKEN_UPDATE = "USER_TOKEN_UPDATE";

//ERROR ACTIONS
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

//SURVEY ACTIONS
export const SET_SURVEY_RESPONSE = "SET_SURVEY_RESPONSE";
export const SENDING_SURVEY = "SENDING_SURVEY";
export const SENT_SURVEY = "SENT_SURVEY";

// CONFIG ACTIONS
export const GET_CONFIGURATIONS = "GET_CONFIGURATIONS";
export const SET_TOAST = "SET_TOAST";

// GENERAL ACTIONS
export const SET_DISPLAY_CONTEXT = "SET_DISPLAY_CONTEXT";
export const MONTHLY_MODAL = "MONTHLY_MODAL";
export const UTM_FIELDS = "UTM_FIELDS";

// PROGRAMS ACTIONS
export const GET_PROGRAMS = "GET_PROGRAMS";
export const PROGRAMS_LOADING = "PROGRAMS_LOADING";
export const PROGRAMS_LOADED = "PROGRAMS_LOADED";
export const GET_COMPLETED_PROGRAMS = "GET_COMPLETED_PROGRAMS";
export const GET_CURRENT_PROGRAM_DAY = "CURRENT_PROGRAM_DAY";
export const GET_CURRENT_PROGRAM = "GET_CURRENT_PROGRAM";

//ERROR TYPES
export const ERROR_TYPES = {
  AUTH_ERROR: { code: "001", title: "Authentication Error" },
  SURVEY_ERROR: { code: "002", title: "Survey Error" },
};
