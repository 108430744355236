import { TSummary, TUser } from "../../types/type";
import {
  AUTH_ERROR,
  AUTHENTICATING,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  RESET_SESSION,
  UPDATE_AUTH,
  USER_LOADED,
  USER_TOKEN_UPDATE,
  USER_LOADING,
  UPDATE_PAIN_AREA,
  UPDATE_APPLICATION_ACCEPTED as UPDATE_APPLICATION_ACCEPTED,
  UPDATE_SUMMARY_FAVORITE,
  SELECTED_LANGUAGE,
} from "../types";

const initialState = {
  isAuthenticated: false,
  isLoading: true,
  token: null,
  attempts: 0,
  user: {} as TUser,
  summary: {} as TSummary,
};

interface authInstance {
  isAuthenticated: boolean;
  isLoading: boolean;
  attempts: number;
  token: string | null;
  user: TUser;
  summary: TSummary;
}

export function authReducer(state: authInstance = initialState, action) {
  switch (action.type) {
    case AUTHENTICATING:
      return {
        ...state,
        attempts: state.attempts + 1,
        isLoading: true,
      };
    case USER_LOADING:
      return {
        ...state,
        user: {
          ...state.user,
          isLoading: true,
        },
      };
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: {
          ...action.payload,
          isLoading: false,
        },
      };
    case USER_TOKEN_UPDATE:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
        isLoading: false,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
      };
    case UPDATE_AUTH:
      return { ...state, ...action.payload };
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT_SUCCESS:
    case REGISTER_FAIL:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
        token: null,
        user: {},
        summary: {},
      };
    case RESET_SESSION:
      return initialState;
    case UPDATE_PAIN_AREA:
      return {
        ...state,
        user: {
          ...state.user,
          summary: {
            ...state.user?.summary,
            painArea: action.payload.painArea,
            applicationSubmittedTimestamp: action.payload.applicationSubmittedTimestamp,
          },
        },
      };
    case UPDATE_APPLICATION_ACCEPTED:
      return {
        ...state,
        user: {
          ...state.user,
          summary: {
            ...state.user?.summary,
            applicationAccepted: action.payload.applicationAccepted,
          },
        },
      };
    case SELECTED_LANGUAGE:
      return {
        ...state,
        user: {
          ...state.user,
          summary: {
            ...state.user?.summary,
            lang: action.payload.language,
          },
        },
      };
    case UPDATE_SUMMARY_FAVORITE:
      return {
        ...state,
        user: {
          ...state.user,
          summary: {
            ...state.user?.summary,
            favorites: action.payload.favorites,
          },
        },
      };
    default:
      return state;
  }
}
